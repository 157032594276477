import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/default.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/dashboard.vue'),
        meta: { view: "all" }
      },
      {
        path: 'trains',
        component: () => import('../views/trains.vue'),
        meta: { view: "all", title: "EXSERVICE | Trains" }
      },
      {
        path: 'trains/:id',
        component: () => import('../views/trainsDetail.vue'),
        meta: { view: "all", title: "EXSERVICE | Trains" }
      },
      {
        path: 'spares',
        component: () => import('../views/spares.vue'),
        meta: { view: "moderator", title: "EXSERVICE | Spares" }
      },
      {
        path: 'samples',
        component: () => import('../views/samples.vue'),
        meta: { view: "admin", title: "EXSERVICE | Samples" }
      },
      {
        path: 'carriage_types',
        component: () => import('../views/carriageTypes.vue'),
        meta: { view: "admin", title: "EXSERVICE | CarriageTypes" }
      },
      {
        path: 'equipment_type',
        component: () => import('../views/equipmentTypes.vue'),
        meta: { view: "admin", title: "EXSERVICE | EquipmentType" }
      },
      {
        path: 'corrective_type',
        component: () => import('../views/correctiveTypes.vue'),
        meta: { view: "admin", title: "EXSERVICE | CorrectiveType" }
      },
      {
        path: 'places',
        component: () => import('../views/Places.vue'),
        meta: { view: "admin", title: "EXSERVICE | Places" }
      },
      {
        path: 'instructions',
        component: () => import('../views/Instructions.vue'),
        meta: { view: "admin", title: "EXSERVICE | Instructions" }
      },
      {
        path: 'tasks',
        component: () => import('../views/Tasks.vue'),
        meta: { view: "admin", title: "EXSERVICE | Tasks" }
      },
      {
        path: 'inspections',
        component: () => import('../views/Inspections.vue'),
        meta: { view: "all", title: "EXSERVICE | Inspections" }
      },
      {
        path: 'inspections/:id',
        component: () => import('../views/InspectionsDetail.vue'),
        meta: { view: "all", title: "EXSERVICE | Inspections" }
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../layouts/Login.vue'),
    meta: { view: "all", title: "EXSERVICE | Login" }
  },
  {
    path: "*",
    component: () => import('../layouts/404.vue'),
    meta: { view: "all", title: "EXSERVICE | 404 NOT FOUND" }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  if (to.path !== '/login' && !localStorage.getItem('token')) {
    next('/login')
  } else  if(to.meta.view === "all"){
    next()
  }else  if(localStorage.getItem('role') == 100){
    next()
  }else  if(localStorage.getItem('role') == 10 && to.meta.view === "moderator"){
    next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'EXSERVICE';
  });
});
export default router
