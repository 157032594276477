import axios from "axios"
import router from "../router"
// import store from "@/store"

// axios.defaults.baseURL = "https://ucs.medias.uz/ru/api/";
// axios.defaults.baseURL = 'https://6f71-83-222-6-187.ngrok-free.app/api/'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + '/api/';

axios.interceptors.request.use(
    async (config) => {
        if(localStorage.getItem('expire_time')) {
            const {tokenExpiration, currentTime} = isTokenValid();
            const isRefreshRequest = config.url && config.url.includes('auth-refresh');
            
            if (!isRefreshRequest && tokenExpiration - currentTime < 1080 && tokenExpiration - currentTime > 0) {
                await refreshToken();
            }
        }
        
        const storageToken = localStorage.getItem("token");
        config.headers["ngrok-skip-browser-warning"] = "any"
        config.headers["Authorization"] = storageToken
            ? `Token ${storageToken}`
            : ""
        
        return config
    },

    error => {
        return Promise.resolve(error)
    }
)

axios.interceptors.response.use(
    function (response) {
        return response || {}
    },
    function (error) {
        const statusCode = (error.response || {}).status || -1

        if ((statusCode === 403 && error.response.data.detail === "Ushbu kirish ma'lumotining amal qilish vaqti tugagan.") || (statusCode === 404 && error.response.data.detail === "Ushbu ma'lumotlar bilan foydalanuvchi topilmadi.")) {
            delete axios.defaults.headers.common["Authorization"]
            localStorage.removeItem("token");
            router.push('/login')
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

let isRefreshing = false;
let refreshPromise;
let inactivityTimeout;

function refreshToken() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');

    if(!isRefreshing) {
        isRefreshing = true;
        refreshPromise = new Promise((resolve, reject) => {
            axios.post('auth-refresh/', {username, token})
                .then((response) => {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('expire_time', response.data.expire_time);

                    clearTimeout(inactivityTimeout);
                    inactivityTimeout = setTimeout(() => {
                        checkExpiration();
                    }, (20 * 60 + 5) * 1000);
                    resolve(response.data.token);
                })
                .catch((err) => {
                    console.log('Error occured while refreshing token: ', err.message)
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                }) 
        });
    }

    return refreshPromise;
}

function isTokenValid() {
    const expirationTimeString = localStorage.getItem('expire_time');
    const parts = expirationTimeString.split(' ');
    const dateParts = parts[0].split('.').reverse().map(part => parseInt(part, 10));
    const timeParts = parts[1].split(':').map(part => parseInt(part, 10));
    const tokenExpiration = Math.floor(new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]).getTime() / 1000);
    const currentTime = Math.floor(Date.now() / 1000);

    return {tokenExpiration, currentTime}
}

function checkExpiration() {
    if(localStorage.getItem('expire_time')) {
        const {tokenExpiration, currentTime} = isTokenValid();

        if (tokenExpiration - currentTime <= 0) {
            delete axios.defaults.headers.common["Authorization"]
            localStorage.removeItem("token");
            router.push('/login')
        }
    }
}

function reloadPage() {
    if( localStorage.getItem('expire_time')) {
        const {tokenExpiration, currentTime} = isTokenValid();
        if (tokenExpiration - currentTime > 0) {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(() => {
                checkExpiration();
            }, (tokenExpiration-currentTime + 5) * 1000);
        }
    }
}

checkExpiration();
reloadPage();